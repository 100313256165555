import React, { Component } from 'react';
import {
  Provider, Pipeline, Values, NoTracking, Response,
  Results,
  Summary
} from "@sajari/sdk-react";
import Result from "./InstantSearchResults";
import { flush } from 'react-emotion'

class InstantSearch extends Component {
  constructor(props) {
    super(props);
    var pipelineConfig = {
      project: "sajariptyltd",
      collection: "sajari-com",
    }

    if (window.TN) {
      pipelineConfig = {
        project: window.TN.Meta['sajari-project'],
        collection: window.TN.Meta['sajari-collection']
      }
    }

    this.state = {
      pipelineConfig: pipelineConfig,
      q: ''
    };

    this.pipeline = new Pipeline(pipelineConfig,
      "website",
      new NoTracking()
    );

    this.values = new Values({
      resultsPerPage: 5
    });

    this.headerSearchInputInput = document.getElementById(props.inputId);
    this.instantSearch = document.getElementById(props.wrapperId);
  }
  onSearch = (e) => {
    if (e.target.value) {
      this.values.set({ q: e.target.value });
      this.pipeline.search(this.values.get());
      this.setState({ ...this.state, q: e.target.value });
    } else {
      this.pipeline.clearResponse()
    }
  }

  handleSuggestionBox = () => {
    this.setState({ ...this.state, q: '' });
    this.headerSearchInputInput.value = '';
    this.instantSearch.style.display = 'none';
  }

  componentDidMount() {
    const me = this
    if (this.headerSearchInputInput && this.instantSearch) {
      this.headerSearchInputInput.addEventListener('keyup', e => {
        me.instantSearch.style.display = 'block';
        me.onSearch(e);
      });
    }

    const aHeaderSearchInput = document.getElementById('aHeaderSearchInput');
    const btnHeaderSearchInput = document.getElementById('btnHeaderSearchInput');

    if (aHeaderSearchInput) {
      aHeaderSearchInput.addEventListener('click', this.handleSuggestionBox)
    }
    if (btnHeaderSearchInput) {
      btnHeaderSearchInput.addEventListener('click', this.handleSuggestionBox)
    }

    flush();
  }

  componentDidUpdate() {
    flush();
  }
  renderSummary = (props) => {
    const { totalResults } = props;

    return (
      <div className="sj-summary">
        <span className="sj-summary__results-text"> {totalResults} results for "<strong className="sj-summary__search-term">{this.state.q}</strong>" </span>
      </div>)
  }

  render() {
    const { pipeline, values } = this;
    const { q } = this.state;

    return (
      <Provider search={{ pipeline, values }}>
        <div>
        </div>
        {
          window.TN ? null : <input type="text" name="q" id="headerSearchInputInput" />
        }
        <div className="searchMainOuter">
          <Response>
            <Summary Renderer={this.renderSummary} />
            <Results ResultRenderer={Result} />
            <div className="showMoreOuter">
              <div>
                <a href={`/search?q=${q}`}>Show all results&nbsp;&nbsp;<span className="fas"></span></a>
              </div>
            </div>
          </Response>
        </div>
      </Provider >
    );
  }
}

export default InstantSearch;
