import * as React from "react";
import get from "dlv";
import { TokenLink } from "@sajari/sdk-react";
import Highlighter from "react-highlight-words";
import { flush } from 'react-emotion'

class Result extends React.Component {
    componentDidMount() {
        flush();
    }
    componentDidUpdate() {
        flush();
    }
    render() {
        const { values, token, resultClicked } = this.props;
        const q = document.getElementById('search-page-input').value;
        const highlightClassName = 'highlight';
        const title = get(values, "title", null);
        const description = get(values, "description", null);
        const url = get(values, "url", null);

        if (!url) {
            return null;
        }
        var type = '';
        if (title.toLowerCase().indexOf('document') > -1 || description.toLowerCase().indexOf('document') > -1) {
            type = 'DOCUMENT';
        }

        const searchWords = q.split(' ');
        return (
            <div className="searchResultDiv">
                <TokenLink
                    url={url}
                    token={token}
                    styles={{}}
                    resultClicked={resultClicked}
                >
                    <h4><Highlighter
                        highlightClassName={highlightClassName}
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={title}
                    /></h4>
                    <div>
                        <p className="searchSummary">
                            <Highlighter
                                highlightClassName={highlightClassName}
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={description}
                            />
                        </p>
                        <p className="searchURL">
                            {type && <span className="anunciatorDocument">{type}</span>}&nbsp;
                            <Highlighter
                                highlightClassName={highlightClassName}
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={url}
                            />
                        </p>
                    </div>
                </TokenLink>
            </div>
        );
    }
}

export default Result;