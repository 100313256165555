import React, { Component } from 'react';
import queryString from 'query-string';
import {
  Provider, Pipeline, Values, NoTracking, Paginator, Response,
  Results,
  Summary
} from "@sajari/sdk-react";
import { flush } from 'react-emotion'
import Result from "./SearchPageResults";

class InstantSearch extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(window.location.search);

    let pipelineConfig = {
      project: "sajariptyltd",
      collection: "sajari-com",
    }

    if (window.TN) {
      pipelineConfig = {
        project: window.TN.Meta['sajari-project'],
        collection: window.TN.Meta['sajari-collection']
      }
    }

    this.state = {
      pipelineConfig: pipelineConfig,
      q: parsed.q,
      sort: parsed.sort || 'relevance',
      resultsPerPage: 20
    };

    this.pipeline = new Pipeline(pipelineConfig,
      "website",
      new NoTracking()
    );

    this.values = new Values({ resultsPerPage: this.state.resultsPerPage });
  }
  onSearch = (e) => {
    var value = typeof e == 'string' ? e : e.target.value;
    if (value) {
      this.values.set({ q: value });
      this.pipeline.search(this.values.get());
    } else {
      this.pipeline.clearResponse()
    }
    this.setState({ ...this.state, q: value });
    this.updateQuery(value, this.state.sort);
  }

  updateQuery = (q, sort) => {
    if (window.history.pushState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?q=${q}&sort=${sort}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  componentDidMount() {
    if (this.state.q) {
      this.values.set({ q: this.state.q });
      var sort = this.state.sort === 'age' ? '-modified_time' : '';
      this.values.set({ sort });

      this.pipeline.search(this.values.get());
    }

    flush();
  }

  componentDidUpdate() {
    flush();
  }

  renderSummary = (props) => {
    const { q } = this.state;
    let { pageNumber, totalResults } = props;
    pageNumber = parseInt((pageNumber || '1').replace(/^\D+/g, ''));
    const count = parseInt(totalResults) || 0;
    const size = this.state.resultsPerPage;
    const start = pageNumber === 1 ? 1 : (pageNumber - 1) * size;
    let end = pageNumber === 1 ? size : start + size;

    if (end > count) {
      end = count;
    }
    if (count > 0) {
      return `Displaying results ${start}–${end} of ${count}`;
    } else {
      return <React.Fragment>
        {'0 results for "'}<mark className="highlight">{q}</mark>{'"'}
      </React.Fragment>;
    }
  }

  scrollToInput = () => {
    // always go the top
    window.scrollTo({
      top: 0,
      behavior: "smooth"   // Optional, adds animation
    })
  }

  sortBy = (e) => {
    this.setState({ ...this.state, sort: e.target.value });

    this.updateQuery(this.state.q, e.target.value);

    var sort = e.target.value === "age" ? '-modified_time' : ''

    this.values.set({ q: this.state.q, sort });
    this.pipeline.search(this.values.get());
  }

  renderPageNumber = ({ pageNumber, onClick, isCurrent }) => {
    if (isCurrent) {
      return <div className="searchPageButton current">
        <a href="javascript:void(0)" className="sj-paginator__page-number sj-paginator__page-number--current" aria-label={"Current Page, Page " + pageNumber} aria-current="true">{pageNumber}</a>
      </div>
    }

    return (<div className="searchPageButton">
      <a href="javascript:void(0)" className="sj-paginator__page-number" onClick={e => { this.scrollToInput(); onClick(); }} aria-label={pageNumber}>{pageNumber}</a>
    </div >)
  }

  renderPreviousButton = ({ isDisabled, onClick }) => {
    if (isDisabled) {
      return null;
    }

    return <div className="searchPrevOuter">
      <button type="button" aria-label="Goto Previous Page" className="sj-paginator__page-button" onClick={e => { this.scrollToInput(); onClick(); }}>
        <span className="fas"></span>
      </button>
    </div>
  }

  renderNextButton = ({ isDisabled, onClick }) => {
    if (isDisabled) {
      return null;
    }

    return <div className="searchNextOuter">
      <button type="button" onClick={e => { this.scrollToInput(); onClick(); }} aria-label="Goto Next Page" className="sj-paginator__page-button">
        <span className="fas"></span>
      </button>
    </div>
  }

  clearInput = () => {
    this.setState({ ...this.state, q: '' })
    this.onSearch('');
  }

  render() {
    const { q, sort } = this.state;
    const { pipeline, values } = this;
    flush();
    return (
      <React.Fragment>
        <div></div>
        <div className="searchMainOuter">
          <form noValidate onSubmit={e => e.preventDefault}>
            <Provider search={{ pipeline, values }}>
              <div className="searchFieldOuter">
                <input type="text" name="q" id="search-page-input" value={q} onChange={this.onSearch} autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" />
                <div className="crossButton" style={{ display: q ? 'block' : 'none' }}>
                  <a href="javascript:void(0)" onClick={this.clearInput}>
                    <span className="far"></span>
                  </a>
                </div>
                <div className="searchIcon">
                  <a href="javascript:void(0)" onClick={() => this.onSearch(q)}>
                    <img src="/assets/img/desktopSearchPageSearch.svg" alt="" />
                  </a>
                </div>
              </div>

              <Response>
                <div className="grid-container full">
                  <div className="grid-x">
                    <div className="cell small-12 medium-6 sj-summary">
                      <Summary Renderer={this.renderSummary} />
                    </div>
                    <div className="cell small-12 medium-6 sortSelect selectStyled">
                      <span>Sort by</span>
                      <select value={sort} onChange={this.sortBy}>
                        <option value="relevance">Relevance</option>
                        <option value="age">Age</option>
                      </select>
                    </div>
                  </div>
                </div>

                <Results ResultRenderer={Result} />

                <Paginator
                  windowSize={3}
                  PageNumberRenderer={this.renderPageNumber}
                  NextButtonRenderer={this.renderNextButton}
                  PreviousButtonRenderer={this.renderPreviousButton} />
              </Response>
            </Provider>
          </form>
        </div>

      </React.Fragment>

    );
  }
}

export default InstantSearch;
