import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import InstantSearch from './Sajari/InstantSearch';
import SearchPage from './Sajari/SearchPage';

import * as serviceWorker from './serviceWorker';

if (document.getElementById('instant-search')) {
    ReactDOM.render(<InstantSearch wrapperId="instant-search" inputId="headerSearchInput" />, document.getElementById('instant-search'));
}

if (document.getElementById('home-instant-search')) {
    ReactDOM.render(<InstantSearch wrapperId="home-instant-search" inputId="home-search-input" />, document.getElementById('home-instant-search'));
}

if (document.getElementById('search-page')) {
    ReactDOM.render(<SearchPage />, document.getElementById('search-page'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
