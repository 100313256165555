import 'react-app-polyfill/ie9';

var objectToValuesPolyfill = function (object) {
    return Object
        .keys(object)
        .map(
            function (key) {
                return object[key];
            }
        );
}

Object.values = Object.values || objectToValuesPolyfill;

// Object.entries

var objectToEntriesPolyfill = function (object) {
    return Object
        .keys(object)
        .map(
            function (key) {
                return [key, object[key]];
            }
        );
}

Object.entries = Object.entries || objectToEntriesPolyfill;

